import styled from 'styled-components'
import * as Popover from '@radix-ui/react-popover'
import { FunnelSimple, XCircle, SignOut } from 'phosphor-react'
import { Archive } from '@mui/icons-material'

export const ArchiveIconStyled = styled(Archive)`
  color: ${({ isArchived }) => (isArchived ? 'red' : 'gray')};
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.Primary};
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.Primary};

  input {
    width: 100%;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.Primary};
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }
`

export const HeaderConfig = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.OnPrimary};
  justify-content: center;
  align-items: center;
`

export const BottomConfig = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.BackgroundMessage};
  padding: 10px;
  flex-direction: column;
`

export const ImageContact = styled.img`
  width: auto;
  max-width: 70px;
  min-width: 70px;
  border-radius: 100%;
  padding: 0.5rem;
`

export const IconsEnd = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding-right: 0.5rem;
`

export const PopoverContent = styled(Popover.Content)`
  background-color: ${({ theme }) => theme.colors.Popover};
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
`

export const PopoverArrow = styled(Popover.Arrow)`
  fill: ${({ theme }) => theme.colors.Popover};
`

export const Item = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
  transition: 0.3s ease;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.PopoverHover};
  }
`

export const ItemText = styled.p`
  cursor: pointer;
`

export const FunnelSimpleStyled = styled(FunnelSimple)`
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.Primary};
  }
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const TitleFilter = styled.p`
  font-size: 1rem;
  font-weight: bold;
`

export const TagAbbreviation = styled.div`
  background-color: ${({ $color, theme, $selected }) =>
    $selected ? `${$color}70` : $color};
  padding: 0.3rem;
  border-radius: 4px;
  display: flex;
  justify-content: ${({ $selected }) =>
    $selected ? 'space-around' : 'center'};
  gap: 5px;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`

export const TextAbbreviation = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: ${({ $color, $selected, theme }) =>
    $selected ? theme.colors.Text : $color};
`

export const XCircleStyled = styled(XCircle)`
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  transition: 0.3s ease;
  width: 20px;

  &:hover {
    color: red;
  }
`

export const BottomConfigSup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
`

export const SelectedTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
`

export const ClearFilters = styled.p`
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    color: red;
  }
`

export const SignOutStyled = styled(SignOut)`
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.Primary};
  }
`

export const ContainerTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 15rem;
  gap: 5px;
`
