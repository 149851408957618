import ContactApi from './Api/ContactApi'

class ContactService {
  async getContact(id) {
    try {
      const response = await ContactApi.getContact(id)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async storeContact(data) {
    try {
      const response = await ContactApi.storeContact(data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getAllContacts() {
    try {
      const response = await ContactApi.getAllContacts()
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getUserContactsByUser(id) {
    try {
      const response = await ContactApi.getUserContactsByUser(id)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getUserContacts() {
    try {
      const response = await ContactApi.getUserContacts()
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createUserContact(data) {
    try {
      const response = await ContactApi.createUserContact(data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteUserContact(id) {
    try {
      const response = await ContactApi.deleteUserContact(id)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async syncImageUser(id) {
    try {
      const response = await ContactApi.syncImageUSer(id)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateContact(id, data) {
    try {
      const response = await ContactApi.updateContact(id, data)
      return response
    } catch (error) {
      return error
    }
  }

  async getAllArchivedContacts() {
    try {
      return await ContactApi.getAllArchivedContacts()
    } catch (error) {
      return error
    }
  }
}

export default new ContactService()
