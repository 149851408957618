import { useEffect, useRef, useState } from 'react'
import {
  Main,
  DateBox,
  Container,
  ImageMine,
  ImageNotMine,
  IconNotMine,
  IconMine,
  ContainerMessage,
  DownloadMessage,
  TimeDifference,
  TooltipContent,
  TooltipArrow,
  Sticker,
  MessageImg,
  MessageVideo,
  VCard,
  VCardName,
  VCardPhone,
  VCardHeader,
  VCardImage,
  VCardButton,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuItemContainer,
  TrashStyled,
  PencilStyled,
} from './styles'
import {
  formatTimeSent,
  formatDate,
  extractImage,
  processMessage,
} from '../../../../../../utils/format'
import DialogSeeMedia from '../../../../../../shared/components/DialogSeeMedia'
import LoadingSpinner from '../../../../../../shared/components/LoadingSpinner'
import { useTheme } from 'styled-components'
import * as Tooltip from '@radix-ui/react-tooltip'
import imageContato from '../../../../../../assets/images/imageContato'
import UserService from '../../../../../../shared/services/User/UserService'
import FileService from '../../../../../../shared/services/File/FileService'
import { useImage } from '../../../../../../hook/image'
import ContactService from '../../../../../../shared/services/Contact/ContactService'
import MessageService from '../../../../../../shared/services/Message/MessageService'
import { useToast } from '../../../../../../hook/toast'
import * as ContextMenu from '@radix-ui/react-context-menu'
import EditMessage from './EditMessage'

const Message = ({
  message,
  dataContact,
  setMessages,
  currentMessages,
  setLateralContacts,
}) => {
  const theme = useTheme()
  const [openEditMessage, setOpenEditMessage] = useState(false)
  const [imageOrientation, setImageOrientation] = useState('')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()
  const [mediaSrc, setMediaSrc] = useState(null)
  const [mediaClicked, setMediaClicked] = useState(false)
  const { contactImage, setUserImage, userImage } = useImage()
  const getUserImage = async (id) => {
    return await UserService.getUserById(id)
  }

  useEffect(() => {
    const loadImage = async () => {
      if (!userImage[message.senderUserId]) {
        try {
          const res = await getUserImage(message.senderUserId)
          if (
            res.data.picture_filename_user &&
            res.data.picture_filetype_user
          ) {
            const base64 = await getBase64Media(
              res.data.picture_filename_user,
              res.data.picture_filetype_user,
            )
            setUserImage(message.senderUserId, base64)
          }
        } catch (error) {
          console.log('Error loading image', error)
        }
      }
    }
    if (message.senderUserId) {
      loadImage()
    }
    // eslint-disable-next-line
  }, [message.senderUserId])

  useEffect(() => {
    if (mediaSrc) {
      if (message.fileType?.startsWith('data:image')) {
        const img = new Image()
        img.src = mediaSrc

        img.onload = () => {
          if (img.naturalWidth > img.naturalHeight) {
            setImageOrientation('landscape')
          } else {
            setImageOrientation('portrait')
          }
        }
      } else if (message.fileType?.startsWith('data:video')) {
        const video = document.createElement('video')
        video.src = mediaSrc

        video.onloadedmetadata = () => {
          if (video.videoWidth > video.videoHeight) {
            setImageOrientation('landscape')
            console.log('landscape')
          } else {
            setImageOrientation('portrait')
            console.log('portrait')
          }
        }
      }
    }
  }, [mediaSrc, message.fileType])

  useEffect(() => {
    if (message.typeMessage === 'sticker') {
      setLoading(true)
      getBase64Media(message.fileName, message.fileType)
        .then((res) => {
          setMediaSrc(res)
          setLoading(false)
        })
        .catch((err) => {
          console.error('Error fetching media:', err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.typeMessage])

  useEffect(() => {
    if (mediaClicked && message.fileName && message.fileType) {
      getBase64Media(message.fileName, message.fileType)
        .then((res) => {
          setMediaSrc(res)
          setLoading(false)
        })
        .catch((err) => {
          console.error('Error fetching media:', err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaClicked, message.fileName, message.fileType])

  useEffect(() => {
    if (videoRef.current) {
      if (open) {
        videoRef.current.pause()
      }
    }
  }, [open])

  const getBase64Media = async (fileName, fileType) => {
    try {
      const response = await FileService.getSrc(fileName, fileType)
      return response.data.base64
    } catch (error) {
      console.log(error)
    }
  }
  const handleMediaClick = () => {
    setLoading(true)
    setMediaClicked(true)
  }

  const videoRef = useRef(null)

  const renderMedia = () => {
    if (loading) {
      return <LoadingSpinner strokeColor={theme.colors.Text} />
    }
    if (!mediaSrc && message.fileType === 'data:image/jpeg;base64') {
      return <p>Clique para carregar imagem</p>
    } else if (!mediaSrc && message.fileType === 'data:video/mp4;base64') {
      return <p>Clique para carregar vídeo</p>
    } else if (!mediaSrc && message.fileType.startsWith('data:audio')) {
      return <p>Clique para carregar áudio</p>
    } else if (!mediaSrc) {
      return <p>Clique para carregar arquivo</p>
    }
    if (message.fileType.startsWith('data:image')) {
      return (
        <MessageImg
          src={mediaSrc}
          onClick={() => setOpen(true)}
          alt="media message"
          className={imageOrientation}
        />
      )
    }

    if (message.fileType.startsWith('data:video')) {
      return (
        <MessageVideo
          ref={videoRef}
          src={mediaSrc}
          onClick={() => setOpen(true)}
          controls
          className={imageOrientation}
        />
      )
    }
    if (message.fileType.startsWith('data:audio')) {
      return (
        <audio
          src={mediaSrc}
          controls
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        />
      )
    }
    return (
      <div className="media-message">
        <a href={mediaSrc} download={message.fileName}>
          Fazer Download do arquivo
        </a>
      </div>
    )
  }

  const handleSaveContact = async () => {
    const fullNameContact = message.message.split(';')[0]
    const idContact = message.message.split(';')[1]
    const data = {
      fullNameContact,
      idContact: idContact.concat('@c.us'),
    }

    const res = await ContactService.storeContact(data)
    if (res.status === 201) {
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Contato salvo com sucesso',
      })
    } else {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Erro ao salvar contato',
      })
    }
  }

  const handleDeleteMessage = (messageId, contactId) => {
    const now = new Date()
    const sendDate = new Date(message.sendDateMessage)
    if (now - sendDate > 172800000) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Tempo de exclusão da mensagem expirado',
      })
    } else {
      try {
        MessageService.delete(messageId)
          .then(() => {
            let message = ''
            setMessages((prev) => {
              const newMessages = { ...prev }
              newMessages[contactId] = newMessages[contactId].filter(
                (msg) => msg.idMessage !== messageId,
              )
              message = newMessages[contactId]
              return newMessages
            })
            setLateralContacts((prev) => {
              const newContacts = [...prev]
              newContacts.map((contact) => {
                if (contact.id === contactId) {
                  if (contact.lastMessageId === messageId) {
                    // lastMessage vai receber a última mensagem que não seja a excluída
                    contact.lastMessage = message[message.length - 1]?.message
                  }
                }
                return contact
              })
              return newContacts
            })
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleEditModal = () => {
    const now = new Date()
    const sendDate = new Date(message.sendDateMessage)
    // console.log(message)

    if (now - sendDate > 840000) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Tempo de edição da mensagem expirado',
      })
      return
    }

    if (message.isNoteMessage) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Não é possível editar mensagens de nota',
      })
      return
    }

    if (
      message.typeMessage !== 'chat' &&
      message.typeMessage !== 'image' &&
      message.typeMessage !== 'video' &&
      message.typeMessage !== 'document'
    ) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Não é possível editar mensagens de mídia',
      })
      return
    }
    setOpenEditMessage(true)
  }

  const renderMessage = () => {
    if (message.typeMessage === 'vcard') {
      const name = message.message.split(';')[0]
      const number = message.message.split(';')[1]
      return (
        <VCard>
          <VCardHeader>
            <VCardImage src={imageContato} alt="Imagem do contato" />
            <VCardName>{name}</VCardName>
          </VCardHeader>
          <VCardPhone>{number}</VCardPhone>
          <VCardButton onClick={() => handleSaveContact()}>
            Salvar contato
          </VCardButton>
        </VCard>
      )
    }
    if (message.message) {
      return <p>{processMessage(message.message)}</p>
    }
  }

  return (
    <Main>
      <DateBox $isFlag={message.isFlag}>
        <p>{formatDate(message.date)}</p>
      </DateBox>
      <Container $isFlag={message.isFlag} $isMine={message.isMine}>
        {extractImage(contactImage[dataContact.id]) !== 'null' ? (
          <ImageNotMine
            $isMine={message.isMine}
            src={contactImage[dataContact.id]}
            alt="Imagem do contato"
          />
        ) : (
          <ImageNotMine
            $isMine={message.isMine}
            src={imageContato}
            alt="Imagem do contato"
          />
        )}
        <IconNotMine $isMine={message.isMine} />
        <ContainerMessage
          $isMine={message.isMine}
          $isNoteMessage={message.isNoteMessage}
        >
          {message.isMine ? (
            <ContextMenu.Root>
              <ContextMenu.Trigger>
                <>
                  {message.typeMessage === 'sticker' ? (
                    loading ? (
                      <LoadingSpinner strokeColor={theme.colors.Text} />
                    ) : (
                      <Sticker
                        src={mediaSrc}
                        alt="Sticker"
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                      />
                    )
                  ) : (
                    message.fileType &&
                    (message.fileType.startsWith('image/') ||
                      message.fileType.startsWith('video/') ||
                      message.fileType.startsWith('audio/') ||
                      message.fileType) && (
                      <DownloadMessage
                        className="media-message"
                        onClick={mediaClicked ? null : handleMediaClick}
                      >
                        {renderMedia()}
                      </DownloadMessage>
                    )
                  )}
                  {renderMessage()}
                  <TimeDifference>
                    {formatTimeSent(message.sendDateMessage)}
                  </TimeDifference>
                </>
              </ContextMenu.Trigger>
              <ContextMenuContent>
                <ContextMenuItemContainer onClick={() => handleEditModal()}>
                  <ContextMenuItem>Editar</ContextMenuItem>
                  <PencilStyled />
                </ContextMenuItemContainer>
                <ContextMenuItemContainer>
                  <ContextMenuItem
                    onClick={() =>
                      handleDeleteMessage(message.idMessage, dataContact.id)
                    }
                  >
                    Apagar
                  </ContextMenuItem>
                  <TrashStyled />
                </ContextMenuItemContainer>
              </ContextMenuContent>
            </ContextMenu.Root>
          ) : (
            <>
              {message.typeMessage === 'sticker' ? (
                loading ? (
                  <LoadingSpinner strokeColor={theme.colors.Text} />
                ) : (
                  <Sticker
                    src={mediaSrc}
                    alt="Sticker"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                )
              ) : (
                message.fileType &&
                (message.fileType.startsWith('image/') ||
                  message.fileType.startsWith('video/') ||
                  message.fileType.startsWith('audio/') ||
                  message.fileType) && (
                  <DownloadMessage
                    className="media-message"
                    onClick={mediaClicked ? null : handleMediaClick}
                  >
                    {renderMedia()}
                  </DownloadMessage>
                )
              )}
              {renderMessage()}
              <TimeDifference>
                {formatTimeSent(message.sendDateMessage)}
              </TimeDifference>
            </>
          )}
        </ContainerMessage>
        <IconMine
          $isMine={message.isMine}
          $isNoteMessage={message.isNoteMessage}
        />
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <ImageMine
                $isMine={message.isMine}
                src={userImage[message.senderUserId] || imageContato}
                style={{
                  cursor: 'pointer',
                }}
              />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <TooltipContent className="TooltipContent" sideOffset={5}>
                {message.userName ? (
                  <p>Enviado por {message.userName}</p>
                ) : (
                  <p>Impossível determinar autor</p>
                )}
                <TooltipArrow />
              </TooltipContent>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
        <DialogSeeMedia
          open={open}
          onOpenChange={setOpen}
          mediaSrc={mediaSrc}
          type={message.typeMessage}
        />
        <EditMessage
          message={message}
          open={openEditMessage}
          onChangeOpen={setOpenEditMessage}
          setMessages={setMessages}
          currentMessages={currentMessages}
          contactId={dataContact.id}
          setLateralContacts={setLateralContacts}
        />
      </Container>
    </Main>
  )
}

export default Message
