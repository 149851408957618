import React from 'react'
import Dialog from '../../../../../../../shared/components/Dialog'
import InputChat from '../../../../../../../shared/components/InputChat'
import { Button, ButtonCancel, ButtonContainer, Title } from './style'
import MessageService from '../../../../../../../shared/services/Message/MessageService'

const EditMessage = ({
  open,
  onChangeOpen,
  message,
  setMessages,
  contactId,
  setLateralContacts,
}) => {
  const [messageState, setMessageState] = React.useState('')

  const handleEditMessage = async () => {
    try {
      const res = await MessageService.update(message.idMessage, {
        contentMessage: messageState,
      })
      if (res.status === 200) {
        setMessages((prev) => {
          const newMessages = { ...prev }
          if (Array.isArray(newMessages[contactId])) {
            newMessages[contactId] = newMessages[contactId].map((msg) => {
              if (msg.idMessage === message.idMessage) {
                return {
                  ...msg,
                  message: messageState,
                }
              }
              return msg
            })
          }
          return newMessages
        })
        setLateralContacts((prev) => {
          const newContacts = [...prev]
          newContacts.map((contact) => {
            if (contact.id === contactId) {
              if (contact.lastMessageId === message.idMessage) {
                contact.lastMessage = messageState
              }
            }
            return contact
          })

          return newContacts
        })
      }

      onChangeOpen(false)
    } catch (error) {
      console.error('Erro ao editar a mensagem:', error)
    }
  }

  return (
    <Dialog open={open} onChangeOpen={onChangeOpen}>
      <Title>Edite a mensagem:</Title>
      <InputChat
        value={messageState}
        onChange={(e) => setMessageState(e.target.value)}
        placeholder={message.message?.replace(/^\n+/, '')}
        handleSendMessage={handleEditMessage}
      />
      <ButtonContainer>
        <ButtonCancel onClick={() => onChangeOpen(false)}>
          Cancelar
        </ButtonCancel>
        <Button
          onClick={handleEditMessage}
          disabled={messageState === message.message}
        >
          Editar
        </Button>
      </ButtonContainer>
    </Dialog>
  )
}

export default EditMessage
